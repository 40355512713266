<template>
  <div>
    <c-card title="작업환경측정결과 엑셀업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            required
            :editable="editable"
            label="공정"
            name="processCd"
            v-model="excelUploadInfo.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="부서"
            name="deptCd"
            v-model="excelUploadInfo.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :editable="editable"
            label="단위작업장소"
            name="workPlace"
            v-model="excelUploadInfo.workPlace">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <c-text
            :editable="editable"
            :disabled="true"
            label="요청부서/이름/일자"
            name="request"
            v-model="request">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'work-measure-result-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          requestContents: '',
          hazardClassFstCd: '',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['workMeasurementResultId'], // 데이터들의 키 속성값
        taskClassCd: 'WMR_RESULT', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            required: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            type: 'process',
            disabled: true,
            processCd: 'processCd',
            align: 'center',
            style: 'width:250px',
          },
          {
            required: true,
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            deptCd: 'deptCd',
            align: 'center',
            type: 'dept',
            style: 'width:250px',
          },
          {
            required: true,
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:250px',
          },
          {
            required: true,
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자명',
            align: 'center',
            style: 'width:250px',
          },
          {
            required: true,
            name: 'measValue',
            field: 'measValue',
            type: 'number',
            label: '측정치',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'exposureFlag',
            field: 'exposureFlag',
            label: '측정농도 평가결과',
            type: 'select',
            comboItems: [
              { code: 'WET0000001', codeName: '초과' },
              { code: 'WET0000002', codeName: '미만' },
              { code: 'WET0000003', codeName: '불검출' },
            ],
            align: 'center',
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태_시간',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태_주기',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '유해인자발생시간',
            align: 'center',
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치(근로자명)',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '시간가중평균치(전회)',
            align: 'center',
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '시간가중평균치(금회)',
            align: 'center',
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
          },
          {
            name: 'measMethod',
            field: 'measMethod',
            label: '측정_분석방법',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'measStartTime',
            field: 'measStartTime',
            type: 'datetime',
            label: '측정시간(시작)',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'measEndTime',
            field: 'measEndTime',
            type: 'datetime',
            label: '측정시간(종료)',
            style: 'width:120px',
            align: 'center',
          },
        ]],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
    // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            this.$emit('closePopup', this.excelUploadInfo.data[0]);
          }
        }
      }
    }
  }
};
</script>
